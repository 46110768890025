import { graphql, Link } from "gatsby";
import * as React from "react";
import { Card, CardBody, CardText } from "reactstrap";
import ContentSection from "../../components/contentSection";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

interface MarkdownNode {
    node: {
        frontmatter: {
            path: string;
            title: string;
            release_date: Date;
        };
        excerpt: string;
    };
}

interface NewsProps {
    data: {
        allMarkdownRemark: {
            edges: MarkdownNode[];
        };
    };
}

const News: React.FunctionComponent<NewsProps> = ({
    data: {
        allMarkdownRemark: { edges }
    }
}) => (
    <Layout>
        <SEO title="News" />

        <ContentSection title="Newsroom">
            {edges &&
                edges.length > 0 &&
                edges.map(
                    (
                        {
                            node: {
                                frontmatter: { title, path, release_date },
                                excerpt
                            }
                        },
                        i
                    ) => (
                        <Card key={`news-item-${i}`} className="my-3">
                            <CardBody>
                                <h2 className="h4">{title}</h2>
                                <CardText className="font-italic">
                                    {release_date}
                                </CardText>

                                <CardText>{excerpt}</CardText>

                                <Link to={path}>Read More</Link>
                            </CardBody>
                        </Card>
                    )
                )}
        </ContentSection>
    </Layout>
);

export default News;

export const pageQuery = graphql`
    {
        allMarkdownRemark(
            sort: { fields: frontmatter___release_date, order: DESC }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        title
                        release_date(formatString: "MMMM D, YYYY")
                    }
                    excerpt
                }
            }
        }
    }
`;
